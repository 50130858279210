import React from 'react'
import FadeIn from './animations/fadeIn'
import { 
    Container,
    Row,
    Col
  } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const Featurette = (props) => {
  const data = useStaticQuery(graphql`
  query featuretteQuery {
      allFile {
        nodes{
          childImageSharp {
            fluid{
              originalName
              aspectRatio
              base64
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  `)
    
  //first get  the array of nodes
  const imageArray = data.allFile.nodes;
  var image;
  //cycle through them avoiding any nulls and then look for the one suppled in props.image, adding the correct one to images
      
  imageArray.forEach(
    node => {
      if (node.childImageSharp){
        if (node.childImageSharp.fluid.originalName===props.image){
          image=node.childImageSharp.fluid;
        }
      }
    }
  );

  const featureText = props.text.map((text) =>
    <p className='font-weight-light py-1'>{text}</p>
  );
  var textOrientation=0;
  if (props.textOrientation==='right'){
    textOrientation = 2;
  }
  if(image){
    if (props.link){
      return (
        <>
          <FadeIn>
            <section className={props.className + ' ' + props.color + ' d-flex align-items-center py-5'}>
              <Container className={'py-5 ' + props.textColor}>
                <Row>
                  <Col xl={{span:4, order:1}} className='d-inline-flex flex-column justify-content-start'>
                    <Img className='z-depth-2 img-fluid mb-5 white-color' fluid={image} alt='feature'/>    
                  </Col>
                  <Col xl={{span:8, order:textOrientation}} className='d-inline-flex flex-column justify-content-start'>
                    <h2 className='font-weight-light'>{props.title}</h2>
                    {featureText}
                    <a href={props.link} className='gtm-button'><span className='btn grey-color white-text'>{props.linkText}</span></a>
                  </Col>
                </Row>
              </Container>
            </section>
          </FadeIn>
        </>
      )
    }
    else {
      return (
        <>
          <FadeIn>
            <section className={props.className + ' ' + props.color + ' d-flex align-items-center py-5'}>
              <Container className={'py-5 ' + props.textColor}>
                <Row>
                  <Col xl={{span:4, order:1}} className='d-inline-flex flex-column justify-content-start'>
                    <Img className='z-depth-2 img-fluid mb-5 white-color' fluid={image} alt='feature'/>    
                  </Col>
                  <Col xl={{span:8, order:textOrientation}} className='d-inline-flex flex-column justify-content-start'>
                    <h2 className='font-weight-light'>{props.title}</h2>
                    {featureText}
                  </Col>
                </Row>
              </Container>
            </section>
          </FadeIn>
        </>
      )
    }
  }
  else{
    return(
    <h2 className='container'>Hello from Featurette component. Your image {props.image} was not found in file structure. Check its name and the files to see you have a match.</h2>
  )};
}

export default Featurette