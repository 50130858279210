import React from 'react';
import { useIntersection } from 'react-use'
import { gsap } from 'gsap/all';


const FadeIn = (props) => {
  //this instead of useEffect since we don't want this to kick off on mounting, we want to wait
  const sectionRef = React.useRef(null);
  const intersection = useIntersection (sectionRef, {root: null, rootMargin: '100px', threshold:1});
  const fadeIn = element =>{
    var x = props.delay;
    gsap.to(element, 4, {delay:x ,opacity:1, ease: 'slow(0.7, 0.7, false)'});
  }
  const fadeOut = element =>{
    gsap.to(element, 4, {opacity:0, ease:'power4.out'});
  }

  //change blah to fade-in if you want to have it go out on leaving screen
  if (intersection){
    intersection && intersection.intersectionRatio < .3
    ? fadeOut ('.blah') : fadeIn ('.fade-in')
  }
  return (
    <>
      <div ref={sectionRef}></div>
      <div className='fade-in'>
        {props.children}
      </div>
    </>
  )
};
  
export default FadeIn;